import { FailureCodes } from 'components/pages/SomethingWentWrongPage/constants';
import Snackbar from 'components/shared/Toaster/ToasterWithoutState';
import { APP_CONFIG } from 'constants/config';
import { IErrorType } from 'interfaces';
import { useRouter } from 'next/router';
import { useSetRecoilState } from 'recoil';
import { userModelState } from 'recoil/atoms/userAtoms';
import { getUserRequest } from 'recoil/selectors/usersSelectors';
import logger from 'utils/logger';
import { getUserClientByActiveClientUuid } from 'utils/userHelper';

import { UserEntity } from '../../../entityTypes/User';
import { ROUTES_PATH_URLS } from '../constants/routes';

export const SET_USER_DATA_ERROR_MESSAGES = {
  NO_RESULTS_FROM_SET_USER_DATA:
    'Unable with request - back-end user and token problem, corrupt get-user-info data or incorrect internal coding error',
  TOKEN_NOT_PROVIDED: 'Token must be provided.',
};

const useSetUserData = () => {
  const setUserModel = useSetRecoilState(userModelState);
  const router = useRouter();

  const setUserData = async (accessToken: string, initiator: string) => {
    try {
      if (!accessToken) {
        Snackbar.error(
          `${SET_USER_DATA_ERROR_MESSAGES.TOKEN_NOT_PROVIDED}, accessToken: ${JSON.stringify(
            accessToken
          )}, Initiator: ${initiator}`
        );
        return;
      }
      logger(
        `useSetUserData :: getUserRequest - Initiator: ${initiator}, setUserData : ${accessToken}`
      );
      const results = await getUserRequest(accessToken);
      const { userModel: resUser } = results;
      const updatedUserModel: UserEntity = {
        ...resUser,
        accessToken,
        tableSettings: [],
        oktaClientId: APP_CONFIG.oktaClientId,
        selectedUserClient: getUserClientByActiveClientUuid(
          resUser?.userClients || [],
          resUser.activeClientUuid
        ),
        isAuth: true,
        signInRetries: 0,
      };
      setUserModel(updatedUserModel);
      return true;
    } catch (error) {
      logger('Error in getUserRequest: ', error);
      const err = error as IErrorType;
      if (err?.code !== 401) {
        if (err?.code === 404) {
          router.push(ROUTES_PATH_URLS.something_went_wrong(FailureCodes.USER_NOT_EXIST));
        } else {
          router.push(ROUTES_PATH_URLS.something_went_wrong(''));
        }
      }

      return false;
    }
  };

  return setUserData;
};

export default useSetUserData;
